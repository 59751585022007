import { React, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getPedido, setPagoPlazo } from '../../../store/slices/pedido'

import { useGetConfigPlazoPagoMutation, useGetTiposPlazosPagoMutation } from '../../../store/api/endpoints/pedidosPlazosApiSlice'

import '../../../assets/css/PedidoOptions.css'

import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Divider } from '@mui/material'


export default function PedidoPlazoPago() {

    const urlParams = useParams()
    const navigate = useNavigate()
    const pedido = useSelector(getPedido)
    const dispatch = useDispatch()

    if (pedido.tokenPedido === null) {
        navigate("/" + urlParams.dominio + "/productos")
    }

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
            error: errorDataGetConfig
        }
    ] = useGetConfigPlazoPagoMutation()

    const [
        getTiposPlazoPago,
        {
            data: responseGetTiposPlazosPago,
            isUninitialized: isUninitializedGetTiposPlazosPago,
            isLoading: isLoadingGetTiposPlazosPago,
            isSuccess: isSuccessGetTiposPlazosPago,
            isError: isErrorGetTiposPlazosPago,
            error: errorDataGetTiposPlazosPago
        }
    ] = useGetTiposPlazosPagoMutation()

    useEffect(() => {
        getConfig()
    }, [])

    useEffect(() => {
        if (isSuccessGetConfig) {
            if (!responseGetConfig.activo) {
                navigate("/" + urlParams.dominio + "/pedido/confirmar")
            } else if (responseGetConfig.opcion_default > 0) {
                console.log(responseGetConfig.opcion_default)
                dispatch(setPagoPlazo(responseGetConfig.opcion_default))
            }
            getTiposPlazoPago()
        }
    }, [isSuccessGetConfig])


    const handleSetPlazoPago = (e) => {

        dispatch(setPagoPlazo(e))
        navigate("/" + urlParams.dominio + "/pedido/confirmar")

    }

    if (isUninitializedGetConfig || isLoadingGetConfig || isUninitializedGetTiposPlazosPago || isLoadingGetTiposPlazosPago) return (<Loading />)

    if (isErrorGetConfig) return (<p>Ocurrió un error al cargar el metodo de pago.</p>)

    if (isSuccessGetConfig && responseGetConfig.activo) return (
        <>
            <div className='header'>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido"}>Pedido</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/metodo-envio"}>Envío</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/metodo-pago"}>Pago</Link>
                    <Typography color="inherit" className='actual'>Plazo</Typography>

                </Breadcrumbs>
            </div>
            <Divider />
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4>Elegir un plazo de pago</h4>
                        {responseGetTiposPlazosPago.map((item, index) => {

                            if (responseGetConfig.elige_cliente || (!responseGetConfig.elige_cliente && responseGetConfig.opcion_default == item.id))
                                return (
                                    <p key={index}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            className="botonLindo"
                                            onClick={() => handleSetPlazoPago(item.id)}
                                        >
                                            {item.nombre}
                                        </Button>
                                    </p>
                                )

                        })}
                    </Grid>
                </Grid>
            </Container>

        </>
    )

}