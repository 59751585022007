import { React } from 'react'
import { useSelector } from 'react-redux'


import { getConfig } from '../../store/slices/config'


import TextoConIcono from '../elementos/TextoConIcono'

import { Divider, Link } from '@mui/material'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import FmdGoodIcon from '@mui/icons-material/FmdGood'

export default function Contacto() {

    const config = useSelector(getConfig)

    return (
        <>
            <div align="left" style={{ padding: '5px' }}>
                <p align="center">Contacto</p>
                <Divider />
                {
                    config.marca.email.length > 3 &&
                    <>

                        <Link href={"mailto:" + config.marca.email}>
                            <TextoConIcono
                                padding={'15px'}
                                icono={<EmailIcon />}
                                texto={config.marca.email}
                            />
                        </Link>
                        <Divider />
                    </>
                }
                {
                    config.marca.tel_whatsapp.length > 3 &&
                    <>
                        <Link href={"https://wa.me/" + config.marca.tel_whatsapp}>
                            <TextoConIcono
                                padding={'15px'}
                                icono={<WhatsAppIcon />}
                                texto={config.marca.tel_whatsapp}
                            />
                        </Link>
                        <Divider />
                    </>
                }
                {
                    config.marca.tel_fijo.length > 3 &&
                    <>
                        <Link href={"tel:" + config.marca.tel_fijo}>
                            <TextoConIcono
                                padding={'15px'}
                                icono={<PhoneEnabledIcon />}
                                texto={config.marca.tel_fijo}
                            />
                        </Link>
                        <Divider />
                    </>
                }
                {
                    config.marca.direccion.length > 3 &&
                    <>
                        <Link href={config.marca.mapa}>
                            <TextoConIcono
                                padding={'15px'}
                                icono={<FmdGoodIcon />}
                                texto={config.marca.direccion}
                            />
                        </Link>
                        <Divider />
                    </>
                }

            </div >

        </>

    )

}