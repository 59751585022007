import { React, ReactNode } from 'react'

import Moment from '../../elementos/Moment'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Divider } from '@mui/material'


export default function PedidosByIdCambiosDeEstado({ responseGetPedido }) {

    return (
        <Box sx={{ width: '100%' }}>
            <Divider />
            <p align="center" style={{ fontSize: '0.9rem', fontWeight: '600' }}>Cambios de Estado</p>
            <Stepper activeStep={responseGetPedido.cambiosDeEstado.length * 2} orientation="vertical">
                <Step>
                    <StepLabel>
                        <p style={{ margin: '0px', fontSize: '0.85rem', fontWeight: '600' }}>Creado</p>
                        <p style={{ margin: '0px', fontSize: '0.65rem', fontWeight: '500' }}>{<Moment date={responseGetPedido.encabezado.fecha} />}</p>
                    </StepLabel>
                </Step>
                {responseGetPedido.cambiosDeEstado.map((estado, index) => {

                    return (
                        <Step key={index}>
                            <StepLabel>
                                <p style={{ margin: '0px', fontSize: '0.85rem', fontWeight: '600' }}>{estado.estado_nuevo}</p>
                                <p style={{ margin: '0px', fontSize: '0.65rem', fontWeight: '500' }}>{<Moment date={estado.fecha} />}</p>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    )

}