import { createSlice } from "@reduxjs/toolkit"

export const pedidoSlice = createSlice({
    name: 'pedido',
    initialState: {
        tokenPedido: null,
        articulos: [], //{id, categoria, subcategoria, slug, titulo, img_principal, precio, cantidad}
        cantArticulos: 0,
        precioTotal: 0,
        envio: {
            tipo: 0, //1: retirar en tienda, 2: enviar a direccion
            direccion: {}
        },
        pago_metodo: {
            id: 0,
        },
        pago_plazo: {
            id: 0,
        },
    },
    reducers: {
        setArticulos: (state, action) => {

            state.articulos = action.payload

            state.precioTotal = state.articulos.map(function (item) { return item.precio * item.cantidad }).reduce((partialSum, a) => partialSum + a, 0)

        },
        addArticulo: (state, action) => {

            let position = state.articulos.map(function (item) { return item.id }).indexOf(action.payload.id)
            if (position !== -1) {
                state.articulos[position].cantidad = state.articulos[position].cantidad + 1
                state.articulos[position].subtotal = state.articulos[position].precio * state.articulos[position].cantidad
            } else {
                action.payload.cantidad = 1
                action.payload.subtotal = action.payload.cantidad * action.payload.precio
                state.articulos.push(action.payload)
            }

            state.precioTotal = state.articulos.map(function (item) { return item.precio * item.cantidad }).reduce((partialSum, a) => partialSum + a, 0)

        },
        removeArticulo: (state, action) => {

            let position = state.articulos.map(function (item) { return item.id }).indexOf(action.payload.id)
            if (position !== -1) {

                let cantidadTemp = state.articulos[position].cantidad

                if (cantidadTemp > 1) {
                    state.articulos[position].cantidad = state.articulos[position].cantidad - 1
                    state.articulos[position].subtotal = state.articulos[position].precio * state.articulos[position].cantidad
                }

                if (cantidadTemp == 1) {

                    state.articulos = state.articulos.filter(function (item) {
                        return item.id != action.payload.id
                    })

                }
            }

            state.precioTotal = state.articulos.map(function (item) { return item.precio * item.cantidad }).reduce((partialSum, a) => partialSum + a, 0)

        },
        limpiarCart: (state, action) => {
            localStorage.removeItem("token-" + action.payload.dominio + "-pedido")
            state.tokenPedido = null
            state.articulos = []
            state.cantArticulos = 0
            state.precioTotal = 0
            state.subtotal = 0
        },
        recalcularPrecioArticulo: (state, action) => {

            let position = state.articulos.map(function (item) { return item.id }).indexOf(action.payload.id)
            if (position !== -1) {
                state.articulos[position].precio = action.payload.precio
                state.articulos[position].subtotal = action.payload.precio * state.articulos[position].cantidad
            }

            state.precioTotal = state.articulos.map(function (item) { return item.precio * item.cantidad }).reduce((partialSum, a) => partialSum + a, 0)

        },
        setEnvio: (state, action) => {

            state.envio.tipo = action.payload

        },
        setPagoMetodo: (state, action) => {

            state.pago_metodo.id = action.payload

        },
        setPagoPlazo: (state, action) => {

            state.pago_plazo.id = action.payload

        },
        setDireccion: (state, action) => {

            state.envio.direccion = {
                id: action.payload.id,
                nombre: action.payload.nombre,
                calle: action.payload.calle,
                altura: action.payload.altura,
                piso: action.payload.piso,
                departamento: action.payload.departamento,
                localidad: action.payload.localidad,
                codigo_postal: action.payload.codigo_postal,
                provincia: action.payload.provincia,
                pais: action.payload.pais,
                observaciones: action.payload.observaciones,
            }

        },
        setTokenPedido: (state, action) => {
            state.tokenPedido = action.payload.tokenPedido
        },
    }
})

export const { setArticulos, addArticulo, removeArticulo, limpiarCart, recalcularPrecioArticulo, setEnvio, setPagoMetodo, setPagoPlazo, setDireccion, setTokenPedido } = pedidoSlice.actions

export const getPedido = (state) => state.pedido

