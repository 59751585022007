import { React, useState } from 'react'
import { useDispatch } from 'react-redux'

import { addArticulo } from '../../../store/slices/pedido'

import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'


export default function Acciones({
    articulo,
}) {

    const dispatch = useDispatch()

    const productoAdd = () => {
        dispatch(addArticulo({
            id: articulo.id,
            codigo: articulo.codigo,
            categoria: articulo.categoria,
            subcategoria: articulo.subcategoria,
            slug: articulo.slug,
            titulo: articulo.titulo,
            img_principal: articulo.img_principal,
            precio: articulo.precio,
        }))
    }

    return (
        <Button
            size="small"
            color="success"
            onClick={() => productoAdd()}
        ><AddIcon /></Button>
    )

}