import Router from './Router'
import { useSelector } from 'react-redux'

import { getConfig } from './store/slices/config'


import { createTheme, ThemeProvider } from '@mui/material/styles'

import './assets/css/App.css'




function App() {

  const config = useSelector(getConfig)

  const theme = createTheme({
    palette: {
      primary: {
        main: (config.colores?.menu ? config.colores.menu : '#eeeeee'),
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#E0C2FF',
        light: '#F5EBFF',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#47008F',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router />
      </div>
    </ThemeProvider>
  )

}

export default App;
