import React from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import '../../../assets/css/Navbar.css'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { getUsuarioEmail } from '../../../store/slices/auth'
import { getStatusSidebar, changeStatusSidebar } from '../../../store/slices/sidebar'
import { getPedido } from '../../../store/slices/pedido'
import { getConfig } from '../../../store/slices/config'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'


export default function Navbar() {

  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const statusSidebar = useSelector(getStatusSidebar)
  const config = useSelector(getConfig)
  const email = useSelector(getUsuarioEmail)
  const pedido = useSelector(getPedido)

  const urlParams = useParams()

  const dispatch = useDispatch()

  const handleToggleMenu = () => {
    dispatch(changeStatusSidebar(!statusSidebar))
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  };



  const btnLeft = <Button onClick={handleToggleMenu}><MenuIcon /></Button>

  const btnRight_con_token =
    <>
      <Tooltip title="Mi Pedido">
        <Link
          to={"/" + urlParams.dominio + "/pedido"}>
          <Badge badgeContent={pedido.articulos.map((item) => { return item.cantidad }).reduce((partialSum, a) => partialSum + a, 0)} color="primary">
            <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
              <ShoppingBasketIcon />
            </IconButton>
          </Badge>
        </Link>
      </Tooltip>
    </>

  const btnRight_sin_token =
    <>
      <Tooltip title="Login">
        <Link
          to="login">
          <IconButton sx={{ p: 0 }}>
            <AccountCircleIcon />
          </IconButton>
        </Link>
      </Tooltip>
    </>


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
            {btnLeft}
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            <img src={config.rutas.logo} style={{margin: '0px', paddingBottom: '0px', height: '50px'}} alt={config.marca.marca} />
          </Typography>

          <Box sx={{ flexGrow: 1, textAlign: 'right', paddingRight: '20px' }} >
            {(email == '' || email == null ? btnRight_sin_token : btnRight_con_token)}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
