import { tiendasApi } from '../api'

export const pedidosPagosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigMetodoPago: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/pagos/config',
                    method: 'GET',
                }
            }
        }),
        getMetodosDePago: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/pagos/metodos',
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    useGetConfigMetodoPagoMutation,
    useGetMetodosDePagoMutation,

} = pedidosPagosApiSlice