import { tiendasApi } from '../api'

export const articulosCategoriaApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getCategorias: builder.mutation({
            query: () => {
                
                return {
                    url: '/articulos-categorias',
                    method: 'GET',
                }

            }
        }),
    })
})

export const {
    useGetCategoriasMutation,

} = articulosCategoriaApiSlice