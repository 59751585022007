import { React, useState } from 'react'
import { useParams } from 'react-router-dom'

import UnProducto from './UnProducto'
import Buscador from './Buscador'
import Categorias from './Categorias'
import Loading from '../../elementos/Loading'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'


export default function Productos() {

    const urlParams = useParams()

    const [loadingProductos, setLoadingProductos] = useState(true)
    const [articulos, setArticulos] = useState([])
    const [categoria, setCategoria] = useState(urlParams.slugCategoria)
    const [subcategoria, setSubcategoria] = useState(urlParams.slugSubcategoria)

    return (
        <>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                        <Buscador
                            articulos={articulos}
                            setArticulos={setArticulos}
                            categoria={categoria}
                            subcategoria={subcategoria}
                            loadingProductos={loadingProductos}
                            setLoadingProductos={setLoadingProductos}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '0px' }}>
                        <Categorias
                            categoria={categoria}
                            setCategoria={setCategoria}
                            setLoadingProductos={setLoadingProductos}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>

                    {
                        loadingProductos && <><Grid item xs={12}><Loading /></Grid></>
                    }
                    {
                        !loadingProductos && articulos.map((item, index) => {

                            return (
                                <UnProducto
                                    key={index}

                                    articulo={item}
                                />
                            )

                        })
                    }


                </Grid>
            </Container>
        </>
    )

}