import { React, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useGetDireccionesMutation } from '../../../store/api/endpoints/direccionesApiSlice'

import { setDireccion } from '../../../store/slices/pedido'

import { getPisoOdepto } from '../../elementos/GetPisoDepto'

import Loading from '../../elementos/Loading'
import EnviarAUnaDireccionDialog from './EnviarAUnaDireccionDialog'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'


import PlaceIcon from '@mui/icons-material/Place'

export default function EnviarAUnaDireccion() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getRegistros,
        {
            data: responseGetRegistros,
            isUninitialized: isUninitializedGetRegistros,
            isLoading: isLoadingGetRegistros,
            isSuccess: isSuccessGetRegistros,
            isError: isErrorGetRegistros,
        }
    ] = useGetDireccionesMutation()


    useEffect(() => {
        getRegistros()
    }, [])
   

    function handleClickDireccion(id){

        let direccionElegida = responseGetRegistros.filter(function(item){ return item.id==id; })[0]

        dispatch(setDireccion({
            id: id,
            nombre: direccionElegida.nombre, 
            calle: direccionElegida.calle, 
            altura: direccionElegida.altura, 
            piso: direccionElegida.piso, 
            departamento: direccionElegida.departamento, 
            localidad: direccionElegida.localidad, 
            codigo_postal: direccionElegida.codigo_postal, 
            provincia: direccionElegida.provincia, 
            pais: direccionElegida.pais, 
            observaciones: direccionElegida.observaciones
        }))

        navigate("/" + urlParams.dominio + "/pedido/metodo-pago")

    }


    if (isUninitializedGetRegistros || isLoadingGetRegistros) return (<Loading />)

    return (
        <>
            <h4>¿A dónde enviamos el pedido?</h4>
            <Box>
                <nav aria-label="main mailbox folders">
                    <List>
                        {
                            responseGetRegistros.map((item, index) => {
                                return (
                                    <ListItem disablePadding key={index} onClick={() => handleClickDireccion(item.id)}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <PlaceIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={item.nombre} secondary={item.calle + " " + item.altura + getPisoOdepto(item.piso, item.departamento) + ", " + item.localidad + " " + item.codigo_postal + ", " + item.provincia} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                        <EnviarAUnaDireccionDialog />
                    </List>
                </nav>
            </Box>
        </>
    )

}