import { tiendasApi } from '../api'

export const authApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: 'self/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        generaTokenSinCredenciales: builder.mutation({
            query: credentials => ({
                url: 'self/genera-token',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        refreshToken: builder.mutation({
            query: token => ({
                url: 'self/refresh-token',
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
        }),
        getSelf: builder.mutation({
            query: () => ({
                url: 'self',
                method: 'GET',
            })
        }),
        getConfig: builder.mutation({
            query: () => ({
                url: 'self/config',
                method: 'GET',
            })
        }),
    })
})

export const {
    useLoginMutation,
    useGeneraTokenSinCredencialesMutation,
    useRefreshTokenMutation,
    useGetSelfMutation,
    useGetConfigMutation,
} = authApiSlice