import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

import { useAbandonadosPedidoUpdateMutation } from '../../../store/api/endpoints/pedidosApiSlice'


import { getPedido, setTokenPedido } from "../../../store/slices/pedido"

import '../../../assets/css/Bottombar.css'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'


export default function Pedido() {

    const delay = 0.4
    const dispatch = useDispatch()
    const pedido = useSelector(getPedido)

    const [
        updatePedido,
        {
            data: responseUpdatePedido,
            isUninitialized: isUninitializedUpdatePedido,
            isLoading: isLoadingUpdatePedido,
            isSuccess: isSuccessUpdatePedido,
            isError: isErrorUpdatePedido,
            error: errorDataUpdatePedido
        }
    ] = useAbandonadosPedidoUpdateMutation()

    useEffect(() => {

        let timer1 = setTimeout(() => {

            if( pedido.articulos.length > 0 ) updatePedido(pedido)

        }, delay * 1000)

        return () => {
            clearTimeout(timer1)
        }

    }, [pedido])

    useEffect(() => {

        if(isSuccessUpdatePedido){
            if(!localStorage.getItem("token-" + urlParams.dominio + "-pedido")) localStorage.setItem("token-" + urlParams.dominio + "-pedido", responseUpdatePedido.tokenPedido)
            if( !pedido.tokenPedido ) dispatch(setTokenPedido({tokenPedido: responseUpdatePedido.tokenPedido}))
        }

    }, [isSuccessUpdatePedido])

    const urlParams = useParams()
    const navigate = useNavigate()

    /*const handleVerPedido = () => {
        if (pedido.articulos.length > 0) {
            navigate("/" + urlParams.dominio + "/pedido")
        } else {
            navigate("/" + urlParams.dominio + "/productos")
        }
    }*/

    return(<></>)

    /*return (
        <Paper className='bottomBar' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Button startIcon={<ShoppingCartIcon />} onClick={handleVerPedido}>
                {
                    pedido.articulos.length > 0 && "Ver Pedido"
                }
                {
                    pedido.precioTotal > 0 && <> (<NumericFormat
                        value={pedido.precioTotal}
                        className="foo"
                        displayType={'text'}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={'$ '}
                    />) </>
                }
                {
                    pedido.articulos.length == 0 && "No hay artículos"
                }
            </Button>
        </Paper>
    );*/
}