import { createSlice } from "@reduxjs/toolkit"

var sideBarInitialState = false

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        status: sideBarInitialState,
    },
    reducers: {
        changeStatusSidebar: (state, action) => {
            state.status = action.payload
        }
    }
})

export const { changeStatusSidebar } = sidebarSlice.actions

export const getStatusSidebar = (state) => state.sidebar.status

