import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetCategoriasMutation } from '../../../store/api/endpoints/categoriaApiSlice'

import Loading from '../../elementos/Loading'


import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'


export default function Categorias({ categoria, setCategoria, setLoadingProductos }) {

    const urlParams = useParams()
    const navigate = useNavigate()

    const [value, setValue] = useState((categoria != undefined ? categoria : ''))

    const [
        getRegistros,
        {
            data: responseGetRegistros,
            isUninitialized: isUninitializedGetRegistros,
            isLoading: isLoadingGetRegistros,
            isSuccess: isSuccessGetRegistros,
            isError: isErrorGetRegistros,
        }
    ] = useGetCategoriasMutation()

    useEffect(() => {
        getRegistros()
    }, [])

    const handleClickCategoria = (slugCategoria) => {

        setLoadingProductos(true)
        setValue(slugCategoria)
        navigate("/" + urlParams.dominio + "/productos/" + slugCategoria)

        setCategoria(slugCategoria)

    }

    if (isUninitializedGetRegistros || isLoadingGetRegistros) return (<Loading />)

    return (
        <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }} style={{ marginBottom: '15px' }}>
            <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab
                    value={''}
                    label="Todas"
                    onClick={() => handleClickCategoria("")}
                />
                {
                    responseGetRegistros.map((unaCategoria) => {
                        return (
                            <Tab
                                key={unaCategoria.id}
                                value={unaCategoria.slug}
                                label={unaCategoria.nombre}
                                onClick={() => handleClickCategoria(unaCategoria.slug)}
                            />
                        )
                    })
                }

            </Tabs>
        </Box>
    );
}