import { React, Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'


import { useGetDireccionesMutation } from '../../../store/api/endpoints/direccionesApiSlice'


import Loading from '../../elementos/Loading'
import TextoConIcono from '../../elementos/TextoConIcono'

import { Divider } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import FmdGoodIcon from '@mui/icons-material/FmdGood'

export default function MiPerfilDirecciones() {

    const [
        getDirecciones,
        {
            data: responseGetDirecciones,
            isUninitialized: isUninitializedGetDirecciones,
            isLoading: isLoadingGetDirecciones,
            isSuccess: isSuccessGetDirecciones,
            isError: isErrorGetDirecciones,
        }
    ] = useGetDireccionesMutation()

    useEffect(() => {
        getDirecciones()
    }, [])

    if (isUninitializedGetDirecciones || isLoadingGetDirecciones) return (<Loading />)
    if (isErrorGetDirecciones) return (<p>Error al cargar la información.</p>)

    return (
        <>
            <div align="left" style={{ padding: '5px' }}>

                <List sx={{ width: '100%' }}>
                    <Divider />
                    {
                        responseGetDirecciones.map((unaDireccion, index) => {
                            return (
                                <Fragment key={index}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FmdGoodIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={unaDireccion.nombre} secondary={unaDireccion.calle + " " + unaDireccion.altura + ", " + unaDireccion.localidad + " (" + unaDireccion.codigo_postal + "), " + unaDireccion.provincia} />
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )
                        })
                    }
                </List>
            </div>
        </>

    )

}