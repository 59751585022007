import { React, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'


import { getPedido } from '../../../store/slices/pedido'

import Articulos from './Articulos'
import Footer from './Footer'

import '../../../assets/css/TablaPedido.css'

import ButtonIcon from '../../elementos/ButtonIcon'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'


import NavigateNextIcon from '@mui/icons-material/NavigateNext'


export default function Pedido() {

    const pedido = useSelector(getPedido)
    const urlParams = useParams()

    return (
        <>
            <div className='tableContainer'>
                <Table size="small" aria-label="Pedido" id="tablaPedido">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Img</TableCell>
                            <TableCell align="left">Artículo</TableCell>
                            <TableCell align="right">Unit.</TableCell>
                            <TableCell align="right">Cant.</TableCell>
                            <TableCell align="right">Subtot.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Articulos
                            articulos={pedido.articulos}
                        />
                    </TableBody>
                    <TableFooter>
                        <Footer
                            pedido={pedido}
                        />
                    </TableFooter>
                </Table>
            </div>
            <p><ButtonIcon variant="outlined" to={"/" + urlParams.dominio + "/pedido/metodo-envio"} color="success" endIcon={<NavigateNextIcon />}>Continuar</ButtonIcon></p>
        </>
    )

}