import { React, useState } from 'react'
import { Link, useParams } from "react-router-dom"

import { NumericFormat } from 'react-number-format'

import Acciones from './Acciones'

import '../../../assets/css/unProducto.css'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'


export default function UnProducto({ articulo }) {

    const urlParams = useParams()

    return (
        <Grid item xs={6} md={3} >
            <Card className='unProducto'>
                <div className='img'>
                    <Link to={"/" + urlParams.dominio + "/producto/" + articulo.slug}>
                        <img src={articulo.img_principal} alt={articulo.titulo} />
                    </Link>
                </div>
                <Link className='link' to={"/" + urlParams.dominio + "/producto/" + articulo.slug}>
                    <CardContent style={{ padding: '3px' }}>
                        <h6 className='titulo' style={{opacity: 0.7}}>{articulo.categoria}{(articulo.subcategoria != '-' ? ' / '+articulo.subcategoria : '')}</h6>
                        <h4 className='titulo'>{articulo.titulo}</h4>
                        {articulo.descripcion && <p className='descripcion'>
                            {articulo.descripcion}
                        </p>
                        }
                        <h2 align="left" className='precio'>
                            <NumericFormat
                                value={articulo.precio}
                                className="foo"
                                displayType={'text'}
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix={'$ '}
                            />
                        </h2>

                    </CardContent>
                </Link>
                <Acciones
                    articulo={articulo}
                />
            </Card>
        </Grid>
    )

}