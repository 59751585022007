import { tiendasApi } from '../api'

export const pedidosPlazosPagoApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigPlazoPago: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/plazos-pago/config',
                    method: 'GET',
                }
            }
        }),
        getTiposPlazosPago: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/plazos-pago/tipos',
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    useGetConfigPlazoPagoMutation,
    useGetTiposPlazosPagoMutation,

} = pedidosPlazosPagoApiSlice