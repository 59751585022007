import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
    name: 'config',
    initialState: {
        marca: {},
        colores: {},
        rutas: {},
        config_servicios: {},
        config_articulos: {},
        config_ventas: {},
    },
    reducers: {
        setConfig: (state, action) => {
            state.config_servicios = action.payload.config_servicios
            state.config_articulos = action.payload.config_articulos
            state.config_ventas = action.payload.config_ventas
            state.marca = action.payload.marca
            state.colores = action.payload.colores
            state.rutas = action.payload.rutas
        },
        cleanConfig: (state, action) => {
            state.config = {}
        },
    }
});

export const { setConfig, cleanConfig } = configSlice.actions

export const getConfig = (state) => state.config



