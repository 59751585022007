import { React, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getPedido, limpiarCart } from '../../../store/slices/pedido'

import { useConfirmarPedidoMutation } from '../../../store/api/endpoints/pedidosApiSlice'


import '../../../assets/css/PedidoOptions.css'

import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Alert, Divider, AlertTitle } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import TaskIcon from '@mui/icons-material/Task'

export default function PedidoConfirmar() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const pedido = useSelector(getPedido)
    const navigate = useNavigate()

    const [
        confirmarPedido,
        {
            data: responseConfirmarPedido,
            isUninitialized: isUninitializedConfirmarPedido,
            isLoading: isLoadingConfirmarPedido,
            isSuccess: isSuccessConfirmarPedido,
            isError: isErrorConfirmarPedido,
            error: errorDataConfirmarPedido
        }
    ] = useConfirmarPedidoMutation()

    useEffect(() => {
        if (pedido.tokenPedido === null) {
            navigate("/" + urlParams.dominio + "/productos")
        }
    }, [])

    const handleClick = () => {
        confirmarPedido({ token: pedido.tokenPedido })
    }

    const handleClickVerPedido = () => {
        navigate("/" + urlParams.dominio + "/pedidos/" + responseConfirmarPedido.id)
    }

    useEffect(() => {

        if (isSuccessConfirmarPedido) {
            dispatch(limpiarCart({ dominio: urlParams.dominio }))
        }

    }, [isSuccessConfirmarPedido])

    if (isLoadingConfirmarPedido) return (<Loading />)

    if (isErrorConfirmarPedido) return (
        <Alert severity="error" style={{ textAlign: 'left' }}>
            <AlertTitle>Error al Enviar</AlertTitle>
            Ocurrió un error al enviar el pedido. Si el problema persiste, por favor ponte en contacto.
        </Alert>
    )

    if (isSuccessConfirmarPedido) return (
        <Alert severity="success" style={{ textAlign: 'left' }}>
            <AlertTitle>Pedido Enviado</AlertTitle>
            El pedido se ha enviado correctamente bajo el N° {responseConfirmarPedido.id}.

            <p>
                <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    className="botonLindo"
                    onClick={handleClickVerPedido}
                >
                    <TaskIcon /> Ver Pedido
                </Button>
            </p>
        </Alert>
    )

    return (
        <>
            <div className='header'>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido"}>Pedido</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/metodo-envio"}>Envío</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/metodo-pago"}>Pago</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/plazo-pago"}>Plazo</Link>
                    <Typography color="inherit" className='actual'>Confirmar</Typography>

                </Breadcrumbs>
            </div>
            <Divider />
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4>Confirmar Pedido</h4>
                        <p>
                            <Button
                                variant="contained"
                                disableElevation
                                fullWidth
                                className="botonLindo"
                                onClick={handleClick}
                            >
                                <CheckIcon /> Confirmar
                            </Button>
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}