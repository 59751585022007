import { React, useState } from 'react'
import { useDispatch } from 'react-redux'

import { removeArticulo } from '../../../store/slices/pedido'

import Button from '@mui/material/Button'

import RemoveIcon from '@mui/icons-material/Remove'


export default function Acciones({
    articulo,
}) {

    const dispatch = useDispatch()

    const productoRemove = () => {
        dispatch(removeArticulo({
            id: articulo.id,
            categoria: articulo.categoria,
            subcategoria: articulo.subcategoria,
            slug: articulo.slug,
            titulo: articulo.titulo,
            img_principal: articulo.img_principal,
            precio: articulo.precio,
        }))
    }

    return (
        <Button
            size="small"
            color="error"
            onClick={() => productoRemove()}
        ><RemoveIcon /></Button>
    )

}