import { tiendasApi } from '../api'

export const pedidosEnviosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigMetodoEnvio: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/envios/config',
                    method: 'GET',
                }
            }
        }),
        getOpcionesMetodoEnvio: builder.mutation({
            query: () => {
                return {
                    url: '/pedidos/envios/opciones',
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    useGetConfigMetodoEnvioMutation,
    useGetOpcionesMetodoEnvioMutation,

} = pedidosEnviosApiSlice