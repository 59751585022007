import { tiendasApi } from '../api'

export const direccionesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getDirecciones: builder.mutation({
            query: () => {
                return {
                    url: '/direcciones',
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    useGetDireccionesMutation,

} = direccionesApiSlice