import { React } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import { useDispatch } from 'react-redux'

import { logOut } from '../../../store/slices/auth'
import { limpiarCart } from '../../../store/slices/pedido'

import LockIcon from '@mui/icons-material/Lock'


export default function Sidebar() {

    const dispatch = useDispatch()
    const urlParams = useParams()
    const navigate = useNavigate()

    
    const handleLogOut = () => {
        dispatch(limpiarCart({ dominio: urlParams.dominio }))
        dispatch(logOut({ dominio: urlParams.dominio }))
        navigate('/' + urlParams.dominio + '/login')
        window.location.reload()
      }

    return (
        <li
            className="sidebar-row"
            onClick={handleLogOut}
        >
            <div className="icono">
                <LockIcon />
            </div>
            <div className="titulo">
                Cerrar Sesión
            </div>
        </li>
    );

}
