import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../../elementos/Loading'

export default function OutletLoginAdmin() {

    const urlParams = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        localStorage.setItem("token-" + urlParams.dominio, urlParams.token)

    }, [])

    useEffect(() => {
        navigate("/" + urlParams.dominio)
    }, [loading])

    if (loading) return (<Loading />)

    return (
        <>
            <p>Aquí tendra que agarrar el token de la URL, generar el token para localstorage y redireccionarlo a donde sea (nuevo pedido / editar pedido)</p>
            <p>Token: {urlParams.token}</p>
        </>
    )

}