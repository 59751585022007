import { React, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux"

import { useRefreshTokenMutation, useGeneraTokenSinCredencialesMutation, useGetConfigMutation } from '../../../store/api/endpoints/authApiSlice'
import { useAbandonadosPedidoGetMutation } from '../../../store/api/endpoints/pedidosApiSlice'

import { getStatusSidebar } from "../../../store/slices/sidebar"
import { getSoloClientes, getUsuarioToken, getUsuarioNombre, setCredentials, logOut } from "../../../store/slices/auth"
import { setTokenPedido, setEnvio, setPagoMetodo, setPagoPlazo, setArticulos } from "../../../store/slices/pedido"
import { setConfig } from "../../../store/slices/config"
import { limpiarCart } from '../../../store/slices/pedido'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Bottombar from './Bottombar'
import Pedido from './Pedido'


import Loading from '../../elementos/Loading'
import Login from '../../vistas/Login'


export default function OutletContenedor() {

    const statusSidebar = useSelector(getStatusSidebar)
    const statusToken = useSelector(getUsuarioToken)
    const soloClientes = useSelector(getSoloClientes)
    const usuarioNombre = useSelector(getUsuarioNombre)

    const urlParams = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loadingLogin, setLoadingLogin] = useState(true)

    const [
        generaTokenSinCredenciales,
        {
            data: responseGeneraTokenSinCredenciales,
            isUninitialized: isUninitializedGeneraTokenSinCredenciales,
            isLoading: isLoadingGeneraTokenSinCredenciales,
            isSuccess: isSuccessGeneraTokenSinCredenciales,
            isError: isErrorGeneraTokenSinCredenciales,
            error: errorDataGeneraTokenSinCredenciales
        }
    ] = useGeneraTokenSinCredencialesMutation()

    const [
        tryRefreshToken,
        {
            data: responseTryRefresh,
            isUninitialized: isUninitializedTryRefresh,
            isLoading: isLoadingTryRefresh,
            isSuccess: isSuccessTryRefresh,
            isError: isErrorTryRefresh,
            error: errorDataTryRefresh
        }
    ] = useRefreshTokenMutation()

    const [
        getPedidoByToken,
        {
            data: responseGetPedidoByToken,
            isUninitialized: isUninitializedGetPedidoByToken,
            isLoading: isLoadingGetPedidoByToken,
            isSuccess: isSuccessGetPedidoByToken,
            isError: isErrorGetPedidoByToken,
            error: errorDataGetPedidoByToken
        }
    ] = useAbandonadosPedidoGetMutation()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
            error: errorDataGetConfig
        }
    ] = useGetConfigMutation()

    const handleLogOut = () => {
        dispatch(limpiarCart({ dominio: urlParams.dominio }))
        dispatch(logOut({ dominio: urlParams.dominio }))
        navigate('/' + urlParams.dominio + '/login')
        window.location.reload()
        setLoadingLogin(false)
    }

    useEffect(() => {

        if (statusToken == null && localStorage.getItem("token-" + urlParams.dominio)) {
            tryRefreshToken(localStorage.getItem("token-" + urlParams.dominio))
        } else {
            generaTokenSinCredenciales({ dominio: urlParams.dominio })
        }

    }, [])

    useEffect(() => {

        if (isSuccessTryRefresh) {
            dispatch(setCredentials({ usuario: responseTryRefresh, dominio: urlParams.dominio }))
            getConfig()
            setLoadingLogin(false)
        }

        if (isErrorTryRefresh) {
            handleLogOut()
        }

    }, [isSuccessTryRefresh, isErrorTryRefresh])

    useEffect(() => {

        if (isSuccessGeneraTokenSinCredenciales) {
            dispatch(setCredentials({ usuario: responseGeneraTokenSinCredenciales, dominio: urlParams.dominio }))
            getConfig()
        }

        if (isErrorGeneraTokenSinCredenciales) {
            handleLogOut()
        }

    }, [isSuccessGeneraTokenSinCredenciales, isErrorGeneraTokenSinCredenciales])

    useEffect(() => {

        if (localStorage.getItem("token-" + urlParams.dominio + "-pedido")) {
            //obtener pedido y asignar a redux
            getPedidoByToken(localStorage.getItem("token-" + urlParams.dominio + "-pedido"))
        } else {
            setLoadingLogin(false)
        }

    }, [statusToken])

    useEffect(() => {

        if (isSuccessGetPedidoByToken) {
            //obtener pedido y asignar a redux
            dispatch(setTokenPedido({ tokenPedido: responseGetPedidoByToken.tokenPedido }))
            dispatch(setEnvio(responseGetPedidoByToken.envio.tipo))
            dispatch(setPagoMetodo(responseGetPedidoByToken.pago_metodo.id))
            dispatch(setPagoPlazo(responseGetPedidoByToken.pago_plazo.id))
            dispatch(setArticulos(responseGetPedidoByToken.articulos))

        }

    }, [isSuccessGetPedidoByToken])

    useEffect(() => {

        if (isSuccessGetConfig) {
            //obtener pedido y asignar a redux
            dispatch(setConfig(responseGetConfig))

        }

    }, [isSuccessGetConfig])


    if (loadingLogin || !statusToken) return (<Loading />)

    if (!soloClientes || (soloClientes && usuarioNombre !== "Anónimo")) {
        return (<>
            <Navbar />
            <Sidebar />

            <div id="routesContainer" className={(!statusSidebar ? 'extendido' : '')}>
                <div id="contenedor">
                    <Outlet />
                </div>
            </div>

            {/*<Bottombar dominio={urlParams.dominio} />*/}
            <Pedido />
        </>)
    }

    if (soloClientes && usuarioNombre === "Anónimo") {
        return (<>
            <Navbar />

            <div id="routesContainer" className={(!statusSidebar ? 'extendido' : '')}>
                <div id="contenedor">
                    <Login />
                </div>
            </div>
        </>)
    }

}