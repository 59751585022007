import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NumericFormat } from 'react-number-format'

import { getPedido } from '../../../store/slices/pedido'


import AccionAdd from './AccionAdd'
import AccionRemove from './AccionRemove'

import '../../../assets/css/unProductoAcciones.css'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'


export default function Acciones({ articulo }) {

    //obtener el cart del pedido y fijarme si tengo ese producto y que cantidad tengo
    var cantidad = 0
    const pedido = useSelector(getPedido)
    let posicionArtEnPedido = pedido.articulos.map(function (item) { return item.id; }).indexOf(articulo.id)
    if (posicionArtEnPedido > -1) {
        cantidad = pedido.articulos[posicionArtEnPedido].cantidad
    }

    return (
        <div className='unProductoAcciones'>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={4} className='actionAdd'>
                        <AccionAdd
                            articulo={articulo}
                        />
                    </Grid>
                    <Grid item xs={4} className='unProductoCount'>
                        <NumericFormat
                            value={cantidad}
                            className="foo"
                            displayType={'text'}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={''}
                        />
                    </Grid>
                    <Grid item xs={4} className='actionRemove'>
                        <AccionRemove
                            articulo={articulo}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )

}