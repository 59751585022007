export function getPisoOdepto(piso, departamento) {

    if (piso == "" && departamento == "") return ""

    let retorno = ""
    if ( (piso != "" && piso != "#") || (departamento != "" && departamento != "#")) retorno += "("
    if (piso != "" && piso != "#") retorno += piso
    if (departamento != "" && departamento != "#") {
        if (piso != "") retorno += " "
        retorno += departamento
    }
    if ( (piso != "" && piso != "#") || (departamento != "" && departamento != "#")) retorno += ")"

    return retorno

}
