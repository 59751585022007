import { React, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { openFeedback } from '../../store/slices/feedback'
import { setCredentials } from '../../store/slices/auth'
import { getPedido, recalcularPrecioArticulo } from '../../store/slices/pedido'

import { useLoginMutation } from '../../store/api/endpoints/authApiSlice'
import { useRecalcularPreciosMutation } from '../../store/api/endpoints/pedidosApiSlice'


import '../../assets/css/vistaFormulario.css'

import Loading from '../elementos/Loading'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Input from '../elementos/Input'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CheckIcon from '@mui/icons-material/Check'
import PasswordIcon from '@mui/icons-material/Password'

export default function Login() {

    const [usuario, setUsuario] = useState('')
    const [contrasena, setContrasena] = useState('')

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pedido = useSelector(getPedido)

    const [
        tryLogin,
        {
            data: responseTryLogin,
            isUninitialized: isUninitializedTryLogin,
            isLoading: isLoadingTryLogin,
            isSuccess: isSuccessTryLogin,
            isError: isErrorTryLogin,
            error: errorDataTryLogin
        }
    ] = useLoginMutation()

    const [
        recalcularPreciosDelPedido,
        {
            data: responseRecalcularPreciosDelPedido,
            isUninitialized: isUninitializedRecalcularPreciosDelPedido,
            isLoading: isLoadingRecalcularPreciosDelPedido,
            isSuccess: isSuccessRecalcularPreciosDelPedido,
            isError: isErrorRecalcularPreciosDelPedido,
            error: errorDataRecalcularPreciosDelPedido
        }
    ] = useRecalcularPreciosMutation()

    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            email: usuario,
            contrasena,
            dominio: urlParams.dominio,
        }

        tryLogin(body)

    }

    useEffect(() => {

        if (isSuccessTryLogin) {

            dispatch(setCredentials({ usuario: responseTryLogin, dominio: urlParams.dominio }))

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Acceso correcto!"
            }))

            if (responseTryLogin.config_ventas.activo) recalcularPreciosDelPedido({ articulos: pedido.articulos.map(function (item) { return item.id }) })
            if (responseTryLogin.config_articulos.activo) navigate('/' + urlParams.dominio + '/productos')

        }

        if (isErrorTryLogin) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataTryLogin.data.message
            }))
        }

    }, [
        isSuccessTryLogin,
        isErrorTryLogin
    ])

    useEffect(() => {

        if (isSuccessRecalcularPreciosDelPedido) {

            for (let i = 0; i < responseRecalcularPreciosDelPedido.length; i++) {
                dispatch(recalcularPrecioArticulo(responseRecalcularPreciosDelPedido[i]))
            }

            navigate("/" + urlParams.dominio + "/home")
            window.location.reload()

        }

        if (isErrorRecalcularPreciosDelPedido) {
            dispatch(openFeedback({
                severity: 'error',
                message: "No se pudo cargar la información. Por favor, intenta nuevamente."
            }))
        }

    }, [
        isSuccessRecalcularPreciosDelPedido,
        isErrorRecalcularPreciosDelPedido
    ])

    if (isLoadingTryLogin || isSuccessTryLogin || isLoadingRecalcularPreciosDelPedido) return (<Loading />)

    return (<>
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <h2>Ingresar</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Usuario"
                            icon={<AccountCircleIcon />}
                            value={usuario}
                            onChange={setUsuario}
                            type="email"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Contraseña"
                            icon={<PasswordIcon />}
                            value={contrasena}
                            onChange={setContrasena}
                            type="password"
                            required
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                <Grid container>
                    <Grid className="botones" item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Ingresar
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <p><small>¿No tenés usuario? <Link to={"/" + urlParams.dominio + "/register"}>¡Registrate aquí!</Link></small></p>

        </form>
    </>)

}