import { React, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useGetArticuloMutation } from '../../../store/api/endpoints/articulosApiSlice'

import '../../../assets/css/Producto.css'

import { NumericFormat } from 'react-number-format'

import Loading from '../../elementos/Loading'
import Acciones from '../Productos/Acciones'
import CategoriaRuta from './CategoriaRuta'
import Subtotal from './Subtotal'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'


export default function Producto() {

    const urlParams = useParams()

    const [
        getArticulo,
        {
            data: responseGetArticulo,
            isUninitialized: isUninitializedGetArticulo,
            isLoading: isLoadingGetArticulo,
            isSuccess: isSuccessGetArticulo,
            isError: isErrorGetArticulo,
        }
    ] = useGetArticuloMutation()

    useEffect(() => {

        getArticulo({ slug: urlParams.slug })

    }, [])

    if (isUninitializedGetArticulo || isLoadingGetArticulo) return (<Loading />)

    if (isErrorGetArticulo) return (<p>Ocurrió un error al obtener el producto.</p>)

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <img className='imgPrincipal' src={responseGetArticulo.img_principal} width="100%" alt={responseGetArticulo.titulo} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <span className='categorias'>
                            <CategoriaRuta
                                categoriaSlug={responseGetArticulo.categoriaSlug}
                                categoriaNomb={responseGetArticulo.categoriaNomb}
                                subcategoriaSlug={responseGetArticulo.subcategoriaSlug}
                                subcategoriaNomb={responseGetArticulo.subcategoriaNomb}
                            />
                        </span>
                        <span className='codigo'>COD. {responseGetArticulo.codigo}</span>
                        <span className='titulo'>{responseGetArticulo.titulo}</span>
                        <span className='descripcion'>{responseGetArticulo.descripcion}</span>
                        {
                            responseGetArticulo.precio != undefined && <span className='precio'>
                                Unit. <NumericFormat
                                    value={responseGetArticulo.precio}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </span>
                        }

                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid item xs={12} md={8}>
                                <p>Cantidad en Pedido</p>
                                <Acciones
                                    articulo={responseGetArticulo}
                                />
                            </Grid>
                        </Grid>
                        <Subtotal 
                            idArticulo={responseGetArticulo.id}
                        />

                    </Grid>
                </Grid>
            </Container>
        </>
    )

}