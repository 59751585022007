import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import '../src/assets/css/Routes.css'

//Componentes Vistas

import OutletContenedor from './components/vistas/OutletContenedor/OutletContenedor'

import Home from './components/vistas/Home'
import Productos from './components/vistas/Productos/Index'
import Producto from './components/vistas/Producto/Index'
import Pedido from './components/vistas/Pedido/Index'
import PedidoMetodoEnvio from './components/vistas/PedidoMetodoEnvio/Index'
import PedidoMetodoPago from './components/vistas/PedidoMetodoPago/Index'
import PedidoPlazoPago from './components/vistas/PedidoPlazoPago/Index'
import PedidoConfirmar from './components/vistas/PedidoConfirmar/Index'
import Contacto from './components/vistas/Contacto'
import MasMenu from './components/vistas/MasMenu'
import PedidosPaginated from './components/vistas/PedidosPaginated/Index'
import PedidosById from './components/vistas/PedidoDetalle/Index'

import Login from './components/vistas/Login'
import Register from './components/vistas/Register'
import MiPerfil from './components/vistas/MiPerfil/Index'


//Login Admin
import LoginAdminOutlet from './components/vistas/LoginAdmin/LoginAdminOutlet'


export default function Router() {

  return (
    <BrowserRouter>

      <Routes >

        <Route path="/:dominio" element={<OutletContenedor />}>
          <Route path="home" element={<Home />} />
          <Route path="productos" element={<Productos />} />
          <Route path="productos/:slugCategoria" element={<Productos />} />
          <Route path="productos/:slugCategoria/:slugSubcategoria" element={<Productos />} />
          <Route path="producto/:slug" element={<Producto />} />
          <Route path="pedido" element={<Pedido />} />
          <Route path="pedido/metodo-envio" element={<PedidoMetodoEnvio />} />
          <Route path="pedido/metodo-pago" element={<PedidoMetodoPago />} />
          <Route path="pedido/plazo-pago" element={<PedidoPlazoPago />} />
          <Route path="pedido/confirmar" element={<PedidoConfirmar />} />
          <Route path="pedidos" element={<PedidosPaginated />} />
          <Route path="pedidos/:id" element={<PedidosById />} />
          <Route path="contacto" element={<Contacto />} />
          <Route path="menu" element={<MasMenu />} />

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="mi-perfil" element={<MiPerfil />} />
        </Route>

        <Route path="/login-admin/:dominio/:token" element={<LoginAdminOutlet />} />

      </Routes>




    </BrowserRouter >
  );

}