import { React, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import Input from '../elementos/Input'

import Button from '@mui/material/Button'

import '../../assets/css/vistaFormulario.css'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CheckIcon from '@mui/icons-material/Check'
import PasswordIcon from '@mui/icons-material/Password'

export default function Login() {

    const [nombre, setNombre] = useState('')
    const [documento, setDocumento] = useState('')
    const [email, setEmail] = useState('')
    const [telefono, setTelefono] = useState('')
    const [contrasena1, setContrasena1] = useState('')
    const [contrasena2, setContrasena2] = useState('')

    const urlParams = useParams()

    const handleSubmit = (e) => {

        e.preventDefault();

    }

    return (<>
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <h2>Registrate</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre"
                            icon={<AccountCircleIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                
                    
                </Grid>
            </Container>

            <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                <Grid container>
                    <Grid className="botones" item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Registrarme
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <p><small>¿Ya tenés usuario? <Link to={"/" + urlParams.dominio + "/login"}>¡Ingresá desde aquí!</Link></small></p>

        </form>
    </>)

}