import { React, useState } from 'react'

import { NumericFormat } from 'react-number-format'


import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'



export default function Articulos({
    pedido
}) {


    return (
        <>
            <TableRow>
                <TableCell align="left" colSpan={3}>Total</TableCell>
                <TableCell align="right" colSpan={2}>
                    <NumericFormat
                        value={pedido.precioTotal}
                        className="foo"
                        displayType={'text'}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={'$ '}
                    />
                </TableCell>
            </TableRow>
        </>
    )

}