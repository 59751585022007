import { React, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getConfig } from "../../../store/slices/config"

import { useGetPedidosPaginatedMutation } from '../../../store/api/endpoints/pedidosApiSlice'

import Loading from '../../elementos/Loading'
import TablaPaginada from '../../elementos/TablaPaginada'

import SearchIcon from '@mui/icons-material/Search'
import { useSelector } from 'react-redux'

export default function Pedidos() {

    const urlParams = useParams()
    const config = useSelector(getConfig)

    const [
        getPedidos,
        {
            data: responseGetPedidos,
            isUninitialized: isUninitializedGetPedidos,
            isLoading: isLoadingGetPedidos,
            isSuccess: isSuccessGetPedidos,
            isError: isErrorGetPedidos,
        }
    ] = useGetPedidosPaginatedMutation()

    useEffect(() => {
        getPedidos()
    }, [])

    if (isUninitializedGetPedidos || isLoadingGetPedidos) return (<Loading />)
    if (isErrorGetPedidos) return (<p>Error al cargar la información.</p>)


    const thead = []
    thead.push({ label: "N°", align: "right" })
    thead.push({ label: "Fecha", align: "center" })
    thead.push({ label: "Estado", align: "center" })
    if( config.config_articulos?.precio?.activo ) thead.push({ label: "Total", align: "right" })
    if( config.config_articulos?.precio?.activo ) thead.push({ label: "Pagos", align: "right" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "id" })
    tbody.push({ tipo: "fecha", valor: "fecha" })
    tbody.push({ tipo: "texto", valor: "estadoNombre" })
    if( config.config_articulos?.precio?.activo ) tbody.push({ tipo: "precio", valor: "total", options: [",", ".", "$"] })
    if( config.config_articulos?.precio?.activo ) tbody.push({ tipo: "precio", valor: "cobranza", options: [",", ".", "$"] })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + "/pedidos/" })
    tbody.push({ tipo: "botones", valor: botones })

    return (
        <>
            <TablaPaginada
                api={useGetPedidosPaginatedMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}