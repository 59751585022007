import { React, useState } from 'react';


export default function MasMenu() {

    return (
        <>
            <p>Aquí va el menú completo de la app.</p>
        </>
    )

}