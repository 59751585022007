import { React, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux'


import { useGetSidebarMutation } from '../../../store/api/endpoints/sidebarApiSlice'

import { getStatusSidebar, changeStatusSidebar } from '../../../store/slices/sidebar'

import '../../../assets/css/Sidebar.css'

import LogOut from './LogOut'
import { SideBarIcon } from './SideBarIcon'

export default function Sidebar() {

    const dispatch = useDispatch()
    const location = useLocation()
    const urlParams = useParams()

    const statusSidebar = useSelector(getStatusSidebar)

    const [
        getSidebar,
        {
            data: responseGetSidebar,
            isUninitialized: isUninitializedGetSidebar,
            isLoading: isLoadingGetSidebar,
            isSuccess: isSuccessGetSidebar,
            isError: isErrorGetSidebar,
            error: errorDataGetSidebar
        }
    ] = useGetSidebarMutation()

    useEffect(() => {

        dispatch(changeStatusSidebar(false))

    }, [location.pathname]);

    useEffect(() => {
        getSidebar(urlParams.dominio)
    }, [])

    if (isUninitializedGetSidebar || isLoadingGetSidebar) return (<></>)

    if (isSuccessGetSidebar) return (
        <div id="sidebar" className={(statusSidebar ? 'open' : 'closed')}>
            <ul>
                {responseGetSidebar.map((val, key) => {
                    return (
                        <Link key={key} to={val.path}>
                            <li
                                className="sidebar-row"
                            >
                                <div className="icono">
                                    {SideBarIcon[val.icono]}
                                </div>
                                <div className="titulo">
                                    {val.titulo}
                                </div>
                            </li>
                        </Link>
                    )
                })}
                <LogOut />
            </ul>
        </div>
    );

}
