import { React, useState } from 'react'


import MiPerfilUsuario from './Usuario'
import MiPerfilDirecciones from './Direcciones'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import FmdGoodIcon from '@mui/icons-material/FmdGood'

export default function MiPerfil() {

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <Tabs value={value} onChange={handleChange} centered aria-label="icon label tabs example">
                    <Tab icon={<AccountCircleIcon />}  label="Perfil" />
                    <Tab icon={<FmdGoodIcon />}  label="Direcciones" />
                </Tabs>
            </Box>
            <br />
            {value==0 && <MiPerfilUsuario />}
            {value==1 && <MiPerfilDirecciones />}
        </>
    )

}