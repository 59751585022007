import { React, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'


import '../../../assets/css/Producto.css'



export default function CategoriaRuta({
    categoriaNomb,
    categoriaSlug,
    subcategoriaSlug,
    subcategoriaNomb,
}) {

    const urlParams = useParams()

    var separador = <></>
    var subcategoria = <></>
    if (subcategoriaNomb) {
        separador = "/ "
        subcategoria = <Link to={"/" + urlParams.dominio + "/productos/" + categoriaSlug + "/" + subcategoriaSlug}>{subcategoriaNomb}</Link>
    }


    return (
        <>
            <Link to={"/" + urlParams.dominio + "/productos/" + categoriaSlug}>{categoriaNomb}</Link> {separador}{subcategoria}
        </>
    )

}