import { React, useEffect, useState } from 'react'


import { useGetArticulosMutation } from '../../../store/api/endpoints/articulosApiSlice'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

const delay = 0.4

export default function Buscador({
    articulos,
    setArticulos,
    categoria,
    subcategoria,
    loadingProductos,
    setLoadingProductos,
}) {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")

    const [
        getRegistros,
        {
            data: responseGetRegistros,
            isUninitialized: isUninitializedGetRegistros,
            isLoading: isLoadingGetRegistros,
            isSuccess: isSuccessGetRegistros,
            isError: isErrorGetRegistros,
        }
    ] = useGetArticulosMutation()

    useEffect(() => {

        let timer1 = setTimeout(() => {

            setLoadingProductos(true)

            getRegistros({
                categoria: (categoria ? categoria : 0),
                subcategoria: (subcategoria ? subcategoria : 0),
                page,
                textsearch,
            })

        }, delay * 1000)

        return () => {
            clearTimeout(timer1)
        }

    }, [textsearch, page])

    useEffect(() => {

        getRegistros({
            categoria: (categoria ? categoria : 0),
            subcategoria: (subcategoria ? subcategoria : 0),
            page,
            textsearch,
        })

    }, [categoria])

    useEffect(() => {

        if (isSuccessGetRegistros) {
            setArticulos(responseGetRegistros.registros)
            setLoadingProductos(false)
        }

        if (isErrorGetRegistros) {
            setLoadingProductos(false)
        }

    }, [isSuccessGetRegistros, isErrorGetRegistros])

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    autoComplete='off'
                    autoFocus
                    onChange={(e) => setTextsearch(e.target.value)}
                    fullWidth
                    label="Buscar Artículos"
                    variant="outlined"
                    value={textsearch}
                />
            </Box>
        </div>
    )

}