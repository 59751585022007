import { tiendasApi } from '../api'

export const sidebarApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getSidebar: builder.mutation({
            query: dominio => ({
                url: 'self/sidebar',
                method: 'GET',
                headers: {
                    'dominio': dominio,
                },
            })
        }),
    })
})

export const {
    useGetSidebarMutation,
} = sidebarApiSlice