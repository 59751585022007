import { React, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getConfig } from "../../../store/slices/config"

import { useGetPedidoByIdMutation } from '../../../store/api/endpoints/pedidosApiSlice'

import Loading from '../../elementos/Loading'
import Moment from '../../elementos/Moment'

import PedidosByIdArticulos from './Articulos'
import PedidosByIdCambiosDeEstado from './CambiosDeEstado'

import { Divider } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'



export default function PedidosById() {

    const urlParams = useParams()
    const config = useSelector(getConfig)

    const [
        getPedido,
        {
            data: responseGetPedido,
            isUninitialized: isUninitializedGetPedido,
            isLoading: isLoadingGetPedido,
            isSuccess: isSuccessGetPedido,
            isError: isErrorGetPedido,
        }
    ] = useGetPedidoByIdMutation()

    useEffect(() => {
        getPedido(urlParams.id)
    }, [])

    if (isUninitializedGetPedido || isLoadingGetPedido) return (<Loading />)
    if (isErrorGetPedido) return (<p>Error al cargar la información.</p>)



    return (
        <>
            <Container component="main" maxWidth="lg" style={{ padding: '0px 15px', fontSize: '0.75rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} align="left">
                        <p><b>N°: </b>{urlParams.id}</p>
                        <Divider />
                        <p><b>Estado: </b>{responseGetPedido.encabezado.estadoNombre}</p>
                        <Divider />
                        <p><b>Fecha: </b>{(responseGetPedido.encabezado.fecha ? <Moment date={responseGetPedido.encabezado.fecha} format="DD-MM-YYYY" /> : "-")}</p>
                        <Divider />
                        {
                            config.config_ventas.envio_metodo.activo && <>
                                <p><b>Método de envío: </b>{responseGetPedido.encabezado.envioMetodo}</p>
                                <Divider />
                                <p><b>Entrega estimada: </b>{(responseGetPedido.encabezado.fecha_estimada_entrega ? <Moment date={responseGetPedido.encabezado.fecha_estimada_entrega} format="DD-MM-YYYY" /> : "-")}</p>
                                <Divider />
                                {
                                    responseGetPedido.encabezado.metodo_envio > 0 &&
                                    <>
                                        <p><b>Dirección: </b>{responseGetPedido.encabezado.direccion}, {responseGetPedido.encabezado.localidad} ({responseGetPedido.encabezado.codigo_postal}), {responseGetPedido.encabezado.provincia}, {responseGetPedido.encabezado.pais}</p>
                                        <Divider />
                                    </>
                                }
                            </>
                        }
                        {
                            config.config_ventas.pago_metodo.activo && <>
                                <p><b>Método de pago: </b>{responseGetPedido.encabezado.pagoMetodo}</p>
                                <Divider />
                            </>
                        }
                        {
                            config.config_ventas.pago_plazo.activo && <>
                                <p><b>Plazo de pago: </b>{responseGetPedido.encabezado.pagoPlazo}</p>
                                <Divider />
                            </>
                        }
                        {
                            config.config_articulos.precio.activo && <>
                                <p><b>Subtotal: </b>{responseGetPedido.encabezado.subtotal}</p>
                                <Divider />
                                <p><b>Total: </b>{responseGetPedido.encabezado.total}</p>
                                <Divider />
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <PedidosByIdArticulos
                            dominio={urlParams.dominio}
                            config={config}
                            responseGetPedido={responseGetPedido}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} align="left">
                        <PedidosByIdCambiosDeEstado
                            responseGetPedido={responseGetPedido}
                        />
                    </Grid>
                </Grid>
            </Container>


        </>
    )

}