import { React, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { setPagoMetodo, getPedido } from '../../../store/slices/pedido'

import { useGetConfigMetodoPagoMutation, useGetMetodosDePagoMutation } from '../../../store/api/endpoints/pedidosPagosApiSlice'


import '../../../assets/css/PedidoOptions.css'

import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Divider } from '@mui/material'


export default function PedidoMetodoPago() {

    const urlParams = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const pedido = useSelector(getPedido)

    if (pedido.tokenPedido === null) {
        navigate("/" + urlParams.dominio + "/productos")
    }

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
            error: errorDataGetConfig
        }
    ] = useGetConfigMetodoPagoMutation()

    const [
        getMetodosDePago,
        {
            data: responseGetMetodosDePago,
            isUninitialized: isUninitializedGetMetodosDePago,
            isLoading: isLoadingGetMetodosDePago,
            isSuccess: isSuccessGetMetodosDePago,
            isError: isErrorGetMetodosDePago,
            error: errorDataGetMetodosDePago
        }
    ] = useGetMetodosDePagoMutation()

    useEffect(() => {
        getConfig()
    }, [])

    useEffect(() => {
        if (isSuccessGetConfig) {
            if (!responseGetConfig.activo) {
                navigate("/" + urlParams.dominio + "/pedido/plazo-pago")
            } else if (responseGetConfig.opcion_default > 0) {
                dispatch(setPagoMetodo(responseGetConfig.opcion_default))
            }
            getMetodosDePago()
        }
    }, [isSuccessGetConfig])



    const handleSetMetodoPago = (e) => {

        dispatch(setPagoMetodo(e))
        navigate("/" + urlParams.dominio + "/pedido/plazo-pago")

    }

    if (isUninitializedGetConfig || isLoadingGetConfig || isUninitializedGetMetodosDePago || isLoadingGetMetodosDePago) return (<Loading />)

    if (isErrorGetConfig) return (<p>Ocurrió un error al cargar el metodo de pago.</p>)

    if (isSuccessGetConfig && responseGetConfig.activo) return (
        <>
            <div className='header'>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido"}>Pedido</Link>
                    <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido/metodo-envio"}>Envío</Link>
                    <Typography color="inherit" className='actual'>Pago</Typography>
                    <Typography color="inherit" >Pago</Typography>
                </Breadcrumbs>
            </div>
            <Divider />
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4>Elegir un método de pago</h4>
                        {responseGetMetodosDePago.map((item, index) => {

                            if (responseGetConfig.elige_cliente || (!responseGetConfig.elige_cliente && responseGetConfig.opcion_default == item.id))
                                return (
                                    <p key={index}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            className="botonLindo"
                                            onClick={() => handleSetMetodoPago(item.id)}
                                        >
                                            {item.nombre}
                                        </Button>
                                    </p>
                                )

                        })}
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}