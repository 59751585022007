import { tiendasApi } from '../api'

export const articulosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getArticulos: builder.mutation({
            query: (data) => {
                
                const { categoria, subcategoria, page, textsearch } = data

                return {
                    url: '/articulos/paginated/' + categoria + "/" + subcategoria,
                    method: 'GET',
                    headers: {
                        page,
                        textsearch
                    },
                }

            }
        }),
        getArticulo: builder.mutation({
            query: (data) => {
                const { slug } = data
                return {
                    url: '/articulos/' + slug,
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    useGetArticulosMutation,
    useGetArticuloMutation,

} = articulosApiSlice