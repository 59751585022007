import { React, useState } from 'react';


export default function Home() {

    return (
        <>
            <p>Esto es el home</p>
        </>
    )

}