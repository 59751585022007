import { React, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"


import { NumericFormat } from 'react-number-format'


import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'



export default function Articulos({
    articulos
}) {

    const urlParams = useParams()
    const navigate = useNavigate()

    const handleClickProducto = (slug) => {

        navigate("/" + urlParams.dominio + "/producto/" + slug)

    }

    if( articulos.length == 0 ) return (<TableRow><TableCell>No hay artículos.</TableCell></TableRow>)

    return (
        <>
            {articulos.map((articulo) => (
                <TableRow
                    key={articulo.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => handleClickProducto(articulo.slug)}
                >
                    <TableCell align="center">
                        <img src={articulo.img_principal} width="50" />
                    </TableCell>
                    <TableCell align="left"><b>{articulo.codigo}</b><br /><span className='titulo'>{articulo.titulo}</span></TableCell>
                    <TableCell align="right">
                        <NumericFormat
                            value={articulo.precio}
                            className="foo"
                            displayType={'text'}
                            renderText={text => <>{text}</>}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'$ '}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumericFormat
                            value={articulo.cantidad}
                            className="foo"
                            displayType={'text'}
                            renderText={text => <>{text}</>}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={''}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumericFormat
                            value={articulo.subtotal}
                            className="foo"
                            displayType={'text'}
                            renderText={text => <>{text}</>}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'$ '}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </>
    )

}