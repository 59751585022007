import { React, useEffect } from 'react'

import { useGetSelfMutation } from '../../../store/api/endpoints/authApiSlice'


import Loading from '../../elementos/Loading'
import TextoConIcono from '../../elementos/TextoConIcono'

import { Divider } from '@mui/material'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'

export default function MiPerfilUsuario() {

    const [
        getSelf,
        {
            data: responseGetSelf,
            isUninitialized: isUninitializedGetSelf,
            isLoading: isLoadingGetSelf,
            isSuccess: isSuccessGetSelf,
            isError: isErrorGetSelf,
        }
    ] = useGetSelfMutation()

    useEffect(() => {
        getSelf()
    }, [])

    if (isUninitializedGetSelf || isLoadingGetSelf) return (<Loading />)
    if (isErrorGetSelf) return (<p>Error al cargar la información.</p>)

    return (
        <>
            <div align="left" style={{ padding: '5px' }}>
                <Divider />
                <TextoConIcono
                    padding={'15px'}
                    icono={<AccountCircleIcon />}
                    texto={responseGetSelf.nombre}
                />
                <Divider />
                <TextoConIcono
                    padding={'15px'}
                    icono={<EmailIcon />}
                    texto={responseGetSelf.email}
                />
                <Divider />
                <TextoConIcono
                    padding={'15px'}
                    icono={<WhatsAppIcon />}
                    texto={responseGetSelf.tel_whatsapp}
                />
                <Divider />
                <TextoConIcono
                    padding={'15px'}
                    icono={<PhoneEnabledIcon />}
                    texto={responseGetSelf.tel_fijo}
                />
                <Divider />
            </div>

        </>

    )

}