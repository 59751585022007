import { React, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getPedido, setEnvio } from '../../../store/slices/pedido'

import { useGetConfigMetodoEnvioMutation, useGetOpcionesMetodoEnvioMutation } from '../../../store/api/endpoints/pedidosEnviosApiSlice'

import EnviarAUnaDireccion from './EnviarAUnaDireccion'
import RetirarEnTienda from './RetirarEnTienda'

import '../../../assets/css/PedidoOptions.css'

import Select from '../../elementos/Select'
import Loading from '../../elementos/Loading'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Divider } from '@mui/material'




export default function PedidoMetodoEnvio() {

    const urlParams = useParams()
    const navigate = useNavigate()
    const pedido = useSelector(getPedido)
    const dispatch = useDispatch()

    if (pedido.tokenPedido === null) {
        navigate("/" + urlParams.dominio + "/productos")
    }

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
            error: errorDataGetConfig
        }
    ] = useGetConfigMetodoEnvioMutation()

    const [
        getOpciones,
        {
            data: responseGetOpciones,
            isUninitialized: isUninitializedGetOpciones,
            isLoading: isLoadingGetOpciones,
            isSuccess: isSuccessGetOpciones,
            isError: isErrorGetOpciones,
            error: errorDataGetOpciones
        }
    ] = useGetOpcionesMetodoEnvioMutation()

    useEffect(() => {
        getConfig()
        getOpciones()
    }, [])

    useEffect(() => {
        if (isSuccessGetConfig) {
            if (!responseGetConfig.activo) {
                navigate("/" + urlParams.dominio + "/pedido/metodo-pago")
            } /*else if (responseGetConfig.opcion_default < 0) {
                dispatch(setEnvio(responseGetConfig.opcion_default))
            }*/
        }
    }, [isSuccessGetConfig])


    const handleSubmit = (e) => {

        e.preventDefault();


        //updateRegistro({ id: urlParams.id, body: body })


    }

    const handleSetMetodoEnvio = (e) => {

        dispatch(setEnvio(e))

    }

    if (isUninitializedGetConfig || isLoadingGetConfig || isLoadingGetOpciones) return (<Loading />)

    if (isErrorGetConfig) return (<p>Ocurrió un error al cargar el envío.</p>)

    if (isSuccessGetConfig && !responseGetConfig.activo) return (<>Redireccionar a metodo de otra cosa, no tiene envío</>)

    if (isSuccessGetConfig && responseGetConfig.activo) {

        let opcionesEnvio = responseGetOpciones.map((opcion, index) => {
            return {
                value: -1 * opcion.id,
                label: opcion.nombre,
            }
        })
        opcionesEnvio.push({ value: 1, label: "Enviar a una dirección" })


        return (
            <>
                <div className='header'>
                    <Breadcrumbs aria-label="breadcrumb" separator="›">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/pedido"}>Pedido</Link>
                        <Typography color="inherit" className='actual'>Envío</Typography>
                        <Typography color="inherit" >Pago</Typography>
                        <Typography color="inherit" >Plazo</Typography>
                    </Breadcrumbs>
                </div>
                <Divider />
                <form onSubmit={handleSubmit}>
                    <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Select
                                    label="¿Retirás o Enviamos?"
                                    value={pedido.envio.tipo}
                                    options={opcionesEnvio}
                                    onChange={(e) => handleSetMetodoEnvio(e)}
                                    required
                                    disabled={!responseGetConfig.elige_cliente}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    {
                        pedido.envio.tipo < 0 &&
                        <RetirarEnTienda />
                    }
                    {
                        pedido.envio.tipo > 0 &&
                        <EnviarAUnaDireccion />
                    }

                </form>
            </>
        )


    }

}