import React from 'react'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import WidgetsIcon from '@mui/icons-material/Widgets'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import WorkIcon from '@mui/icons-material/Work'
import TaskIcon from '@mui/icons-material/Task'
import SearchIcon from '@mui/icons-material/Search'

export const SideBarIcon = [
    null,                       //0
    <AccountCircleIcon />,      //1
    <WorkIcon />,               //2
    <WidgetsIcon />,            //3
    <PhoneEnabledIcon />,       //4
    <TaskIcon />,               //5
    <SearchIcon />,             //6
]