import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { store } from './store'

import App from './App'
import reportWebVitals from './reportWebVitals'

import Feedback from './components/elementos/Feedback'


import './assets/css/index.css'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Feedback />
      <div className="fondoLogo"></div>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
