import { React } from 'react'

import { Link, useParams } from 'react-router-dom'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'


export default function RetirarEnTienda() {

    const urlParams = useParams()

    return (
        <>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p>Esto es retirar en tienda. Será un texto que puede modificar la tienda.</p>
                    </Grid>
                </Grid>
            </Container>
            <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                <Grid container>
                    <Grid className="botones" item xs={12} >
                        <Link to={"/" + urlParams.dominio + "/pedido/metodo-pago"}>
                            <Button type="submit" variant="contained" color="success" endIcon={<NavigateNextIcon />} >
                                Continuar
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}