import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getPedido } from '../../../store/slices/pedido'


import '../../../assets/css/Producto.css'

import { NumericFormat } from 'react-number-format'


export default function Subtotal({
    idArticulo,
}) {

    const pedido = useSelector(getPedido)

    const [loading, setLoading] = useState(true)
    const [subtotal, setSubtotal] = useState(0)
    

    useEffect(() => {

        let articulos = pedido.articulos.filter(function(item){ return item.id == idArticulo })

        if( articulos.length == 1 ){
            setSubtotal(articulos[0].cantidad*articulos[0].precio)
            setLoading(true)
        }else{
            setSubtotal(NaN)
            setLoading(true)
        }

    }, [pedido])
    
    if( !loading ) return (<></>)

    if( isNaN(subtotal) ) return (<></>)

    return (
        <p className='subtotal'>Subtotal: <NumericFormat
            value={subtotal}
            className="foo"
            displayType={'text'}
            decimalSeparator=","
            thousandSeparator="."
            prefix={'$ '}
        /></p>
    )

}