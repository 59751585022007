import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        solo_clientes: null,
        token: null,
        nombre: null,
        permisos: [],
    },
    reducers: {
        setCredentials: (state, action) => {
            state.solo_clientes = action.payload.usuario.solo_clientes
            state.token = action.payload.usuario.token
            state.nombre = action.payload.usuario.usuario.nombre
            state.email = action.payload.usuario.usuario.email
            localStorage.setItem("token-"+action.payload.dominio, action.payload.usuario.token)
        },
        logOut: (state, action) => {
            state.solo_clientes = null
            state.token = null
            state.nombre = null
            state.email = null
            localStorage.removeItem("token-"+action.payload.dominio)
        },
    }
});

export const { setCredentials, logOut } = authSlice.actions

export const getSoloClientes = (state) => state.auth.solo_clientes
export const getUsuarioNombre = (state) => state.auth.nombre
export const getUsuarioEmail = (state) => state.auth.email
export const getUsuarioToken = (state) => state.auth.token
export const getUsuarioPermisos = (state) => state.auth.permisos



