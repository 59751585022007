import moment from 'moment-timezone'

export default function Moment({
    date,
    timezone = "America/Argentina/Buenos_Aires",
    format = "DD-MM-YYYY HH:mm"
}) {

    return (moment(date).tz(timezone).format(format))

}