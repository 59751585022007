import { tiendasApi } from '../api'

export const pedidosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPedidosPaginated: builder.mutation({
            query: (token) => {
                return {
                    url: '/pedidos',
                    method: 'GET',
                }
            }
        }),
        getPedidoById: builder.mutation({
            query: (idPedido) => {
                return {
                    url: '/pedidos/' + idPedido,
                    method: 'GET',
                }
            }
        }),
        recalcularPrecios: builder.mutation({
            query: (data) => {
                const { articulos } = data
                return {
                    url: '/pedidos/recalcular-precios',
                    method: 'POST',
                    body: {
                        articulos
                    },
                }
            }
        }),
        confirmarPedido: builder.mutation({
            query: (data) => {
                return {
                    url: '/pedidos/confirmar',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        abandonadosPedidoGet: builder.mutation({
            query: (token) => {
                return {
                    url: '/pedidos/aband/' + token,
                    method: 'GET',
                }
            }
        }),
        abandonadosPedidoUpdate: builder.mutation({
            query: (body) => {
                return {
                    url: '/pedidos/aband',
                    method: 'POST',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetPedidosPaginatedMutation,
    useGetPedidoByIdMutation,
    useRecalcularPreciosMutation,
    useConfirmarPedidoMutation,
    useAbandonadosPedidoGetMutation,
    useAbandonadosPedidoUpdateMutation,

} = pedidosApiSlice